import { useEffect, useState } from 'react';

import DashboardPage from './pages/Dashboard';
import LoginPage from './pages/Login';
import { getAuthStatus } from './auth';


function App() {
    const [authStatus, setAuthStatus] = useState(false);
    useEffect(() => {
        getAuthStatus().then(status => setAuthStatus(status));
    }, []);

    return (
        <>
            { authStatus ? <DashboardPage /> : <LoginPage /> }
        </>
    );
}

export default App;
