import { FormLabel, Typography, Select, Option, Input, Button } from "@mui/joy";
import { AddCard, FormFieldStack } from "./styledElements";
import { useEffect, useState } from "react";
import { getExpenseCategories, getLimits, postDataWithAuth } from "../api"
import { limitsURL } from "../constants";


const AddLimit = () => {
    // Storage for user's data 
    const [formData, setFormData] = useState({ cummulative: false });
    // Load categories on element's first render 
    const [categories, setCategories] = useState([]);
    useEffect(() => { 
        getExpenseCategories().then(data => {
            getLimits().then(limits => {
                const occupiedCategories = limits.map(d => d.category.uid);
                const emptyCategories = data.filter(cat => !occupiedCategories.includes(cat.uid));
                setCategories(emptyCategories);
            });
        });
    }, []);

    /** 
     * Posts data to API on form submit
     */
    const handleSubmit = (e) => {
        e.preventDefault();

        const updatedFormData = { 
            ...formData, 
            value: parseFloat(formData.value), 
            cumulative_value: parseFloat(formData.value), 
            start_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1), 
            ending_date: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), 
        };
        setFormData(updatedFormData);
        postDataWithAuth(limitsURL, formData).then(result => {
            if (result) window.location.reload();
            else console.log('Error saving limit');
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <AddCard>
                <Typography component="h1">Добавить бюджетную цель</Typography>
                <FormFieldStack>
                    <FormLabel>Категория</FormLabel>
                    <Select value={formData.category || null} onChange={(_, value) => setFormData(prevState => ({ ...prevState, category: value }))}>
                        { categories && categories.map(cat => <Option key={cat.uid} value={cat.uid}>{ cat.title }</Option>) }
                    </Select>
                </FormFieldStack>

                <FormFieldStack>
                    <FormLabel>Сумма</FormLabel>
                    <Input type="number" value={ formData.value || '' } onChange={(e) => setFormData(prevState => ({ ...prevState, value: e.target.value })) } />
                </FormFieldStack>

                <FormFieldStack>
                    <FormLabel>Что делать с остатком по окончании периода?</FormLabel>
                    <Select value={formData.cummulative || false} onChange={(_, value) => setFormData(prevState => ({ ...prevState, cummulative: value }))}>
                        <Option value={true}>Добавлять к сумме следующего периода</Option>
                        <Option value={false}>Не сохранять</Option>
                    </Select>
                </FormFieldStack>

                <Button type="submit">Добавить</Button>
            </AddCard>
        </form>
    )
};

export default AddLimit;