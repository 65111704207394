import { useEffect, useState } from "react"
import { getLimits, postDataWithAuth } from "../api";
import { AddCard } from "./styledElements";
import { Typography, Box } from "@mui/joy";
import { barColor, formatFloat } from "../formatData";


const BudgetSummary = () => {
    const [limits, setLimits] = useState([]);
    const [ratio, setRatio] = useState(1);
    const [totals, setTotals] = useState({ total: 1, left: 0 })
    useEffect(() => { 
        getLimits().then(data => {
            for (let d of data) {
                postDataWithAuth(`http://${process.env.REACT_APP_API_HOST}/api/data/expense/category/limits/renew/${d.uid}`).then(res => {});
            };
        });
        
        getLimits().then(data => {
            setLimits(data);
            const totalValue = data.map(d => d.cumulative_value).reduce((a, b) => a + b, 0);
            const totalLeft = data.map(d => d.current_balance).reduce((a, b) => a + b, 0);
            setRatio(Math.max(1, Math.floor((totalLeft / totalValue) * 100)));
            setTotals({ total: totalValue, left: totalLeft });
        });
    }, []);

    return (
        <AddCard>
            <Typography component="h1">Сводка бюджетных целей</Typography>
            <Typography>Целей установлено: { limits.length }</Typography>
            <Typography>{ formatFloat(totals.left) } / { formatFloat(totals.total) }</Typography>
            <Box sx={{ height: '8px', width: `${ratio}%`, backgroundColor: barColor(ratio), borderRadius: '4px' }}/>
        </AddCard>
    )
};

export default BudgetSummary;