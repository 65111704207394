import { Stack, Typography } from "@mui/joy";
import { useEffect, useState } from "react"
import { getAccounts } from "../api";
import { AddCard } from "./styledElements";
import { formatFloat } from "../formatData";

const AccountsSmallTable = () => {
    const [accounts, setAccounts] = useState([]);
    useEffect(() => {
        getAccounts().then(data => setAccounts(data));
    }, []);

    return (
        <Stack direction="row" flexWrap='wrap' gap={2}>
            { accounts.length > 0 && accounts.map(acc => <AccountCard key={acc.uid} account={acc} />)}
        </Stack>
    )
};

export default AccountsSmallTable;


const AccountCard = ({ account }) => {
    const balanceColor = () => {
        if (account.balance > 0) {
            return 'success';
        } else if (account.balance < 0) {
            return 'danger';
        } else if (account.balance === 0) {
            return 'neutral';
        }
    };

    return (
        <AddCard direction='row' alignItems='center' sx={{ minWidth: 'unset !important', width: 'fit-content', gap: '50px !important' }}>
            <Stack direction='column'>
                <Typography fontWeight='bold'>{ account.title }</Typography>
                <Typography>{ account.account_type }</Typography>
            </Stack>
            <Typography color={balanceColor()}>{ formatFloat(account.balance) }</Typography>
        </AddCard>
    )
}