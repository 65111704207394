import { useEffect, useState } from "react";
import { Box, Stack, Typography, Input, FormLabel, Table, Snackbar, Button } from "@mui/joy";
import { MapContainer, TileLayer, CircleMarker, LayersControl, FeatureGroup, Popup } from "react-leaflet";
import { fetchRecords, getExpenseCategories } from "../api";
import { formatDate, formatFloat } from "../formatData";
import { AddCard, FormFieldStack } from "./styledElements";


const ExpensesMap = () => {
    const [mapCenter, setMapCenter] = useState({});
    const [loadingStatus, setLoadingStatus] = useState(0);

    // Fetch categories on element's first render 
    const [categories, setCategories] = useState([]);
    useEffect(() => { getExpenseCategories().then(data => { if (data) setCategories(data); setLoadingStatus(1); }) }, []);
    
    // Filter values     
    const [valueRange, setValueRange] = useState({});
    const [dateRange, setDateRange] = useState({});
    // Categories that match filters 
    const [filteredCategories, setFilteredCategories] = useState([]);
    // Whether to trigger filters application snackbar 
    const [showFiltersSnackbar, setShowFiltersSnackbar] = useState(false);

    useEffect(() => {
        if ((loadingStatus >= 1) && (categories.length > 0)) {
            // Fetch expenses 
            fetchRecords(null, null, 'expense/records').then(data => {
                if (data) {
                    // Filter records that have coordinates, sort them by value (descending) and convert date string to date objects 
                    const updatedData = data.filter(d => d.latitude && d.longitude).sort((a, b) => (b.value - a.value)).map(exp => ({ ...exp, user_date_time: new Date(exp.user_date_time) }));
                    // Center map on most expensive expense 
                    setMapCenter({ lat: updatedData[0].latitude, lng: updatedData[0].longitude });
                    
                    // Update value range in filters 
                    setValueRange({
                        min: updatedData[updatedData.length - 1].value,
                        max: updatedData[0].value, 
                        lambda: updatedData[0].value - updatedData[updatedData.length - 1].value
                    });
                    // Update dates range in filters 
                    setDateRange({ 
                        min: new Date(Math.min(...updatedData.map(exp => exp.user_date_time))).toJSON().slice(0, 10), 
                        max: new Date(Math.max(...updatedData.map(exp => exp.user_date_time))).toJSON().slice(0, 10), 
                    });

                    // Count expenses for each category, filter the categories that have at least 1 expense, colorize them 
                    const updatedCategories = categories.map(cat => ({ ...cat, expenses: updatedData.filter(e => e.category.uid === cat.uid) }))
                                                        .filter(cat => cat.expenses.length > 0)
                                                        .map((cat, index) => ({ ...cat, color: categoryColor(index) }));
                    setCategories(updatedCategories);
                    setFilteredCategories(updatedCategories);
                    setLoadingStatus(2);
                };
            });
        };
    }, [loadingStatus]);

    /**
     * Update data view on filters application 
     * @param { Event } e 
     */
    const handleFiltersChange = (e) => {
        e.preventDefault();
        const minDate = new Date(dateRange.min);
        const maxDate = new Date(dateRange.max);
        // Update filtered categories array and colorize those, that have no color yet
        const filteredCategories = categories.map(cat => ({ 
            ...cat, 
            expenses: cat.expenses.filter(exp => ((exp.value >= valueRange.min) && (exp.value <= valueRange.max)) && (exp.user_date_time >= minDate) && (exp.user_date_time <= maxDate) )
        })).filter(cat => cat.expenses.length > 0).map((cat, index) => ({
            ...cat, 
            color: cat.color || categoryColor(index)
        }));
        setFilteredCategories(filteredCategories);
        // Notify 
        setShowFiltersSnackbar(true);
    };

    /**
     * Calculate marker radius using min-max scaller, that is dependent on min and max value of showing expense. 
     * @param { number } value - Expense value
     * @returns { number } Marker radius value
     */
    const markerRadius = (value) => {
        const minRadius = 3, maxRadius = 15, radiusLambda = maxRadius - minRadius;
        const ratio = (value - valueRange.min) / valueRange.lambda;
        return minRadius + ratio * radiusLambda;
    };

    if (loadingStatus < 2) {
        return <Typography>Loading...</Typography>
    }

    return (
        <Stack direction={{ xs: 'column', md: 'row-reverse'}} gap={2}>
            <Stack direction="column" gap={2}>
                <AddCard>
                    <Typography>
                        Показано записей: { filteredCategories.length > 0 ? filteredCategories.map(cat => cat.expenses.length).reduce((a, b) => a + b, 0) : 0 }
                    </Typography>
                </AddCard>
                <form onSubmit={handleFiltersChange}>
                    <AddCard>
                        <Typography component="h1">Фильтры</Typography>
                        <FormFieldStack>
                            <FormLabel>Минимальная сумма</FormLabel>
                            <Input type="number" value={ valueRange.min } required onChange={(e) => setValueRange(prevState => ({ ...prevState, min: parseFloat(e.target.value) }))} />
                        </FormFieldStack>
                        <FormFieldStack>
                            <FormLabel>Максимальная сумма</FormLabel>
                            <Input type="number" value={ valueRange.max } required onChange={(e) => setValueRange(prevState => ({ ...prevState, max: parseFloat(e.target.value) }))} />
                        </FormFieldStack>
                        <FormFieldStack>
                            <FormLabel>Минимальная дата</FormLabel>
                            <Input type="date" value={ dateRange.min } required onChange={(e) => setDateRange(prevState => ({ ...prevState, min: e.target.value }))} />
                        </FormFieldStack>
                        <FormFieldStack>
                            <FormLabel>Максимальная дата</FormLabel>
                            <Input type="date" value={ dateRange.max } required onChange={(e) => setDateRange(prevState => ({ ...prevState, max: e.target.value }))} />
                        </FormFieldStack>
                        <Button type="submit">Применить</Button>
                    </AddCard>
                </form>
            </Stack>
            <Box height='82svh' width='100%'>
                <MapContainer center={[mapCenter.lat, mapCenter.lng]} zoom={11} scrollWheelZoom={true} style={{ height: '100%', width:'100%' }}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                        url='https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png'
                        subdomains='abcd'
                    />
                    <LayersControl position="topright">
                        { filteredCategories.map(cat => (
                            <LayersControl.Overlay key={cat.uid} name={`${cat.title} (${cat.expenses?.length})`} checked>
                                <FeatureGroup pathOptions={{ color: cat.color }}>
                                    { cat.expenses.map(exp => (
                                        <CircleMarker key={exp.uid} center={{ lat: exp.latitude, lng: exp.longitude }} radius={ markerRadius(exp.value) }>
                                            <MarkerTooltip expense={exp} />
                                        </CircleMarker>
                                    ))}
                                </FeatureGroup>
                            </LayersControl.Overlay>
                        ))}
                    </LayersControl>
                </MapContainer>
            </Box>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} variant="solid" color="success" autoHideDuration={2000} open={showFiltersSnackbar} onClose={() => setShowFiltersSnackbar(false)}>
                Фильтры применены
            </Snackbar>
        </Stack>
    )
};

export default ExpensesMap;


const MarkerTooltip = ({ expense }) => {
    return (
        <Popup>
            <Table sx={{ width: '100%', tableLayout: 'auto' }}>
                <tbody>
                    <tr>
                        <th>Дата</th>
                        <td>{ formatDate(expense.user_date_time) }</td>
                    </tr>
                    <tr>
                        <th>Сумма</th>
                        <td>{ formatFloat(expense.value) }</td>
                    </tr>
                    <tr>
                        <th>Категория</th>
                        <td>{ expense.category.title }</td>
                    </tr>
                    <tr>
                        <th>Счёт</th>
                        <td>{ expense.account.title }</td>
                    </tr>
                </tbody>
            </Table>
        </Popup>
    )
};


function categoryColor (index) {
    let colors = ['green', 'purple', 'blue', 'red', 'yellow', 'orange'];
    return colors[index % colors.length];
};
