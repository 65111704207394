import { Stack, styled } from "@mui/joy";


export const AddCard = styled(Stack)(({ theme }) => ({
    direction: 'column', 
    gap: theme.spacing(2), 
    padding: theme.spacing(2), 
    boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.25)', 
    borderRadius: 5, 
    minWidth: '200px', 
}));

export const FormFieldStack = styled(Stack)(({ theme }) => ({
    direction: 'column', 
    gap: theme.spacing(1),
}));

export const SettingsTabStack = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(3)
}));