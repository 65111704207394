/**
 * API path to request new token pair
 */
export const getTokenURL = `http://${process.env.REACT_APP_API_HOST}/api/token/`;
/**
 * API path to verify current token
 */
export const verifyTokenURL = `http://${process.env.REACT_APP_API_HOST}/api/token/verify/`;
/**
 * API path to refresh access token using current refresh token 
 */
export const refreshTokenURL = `http://${process.env.REACT_APP_API_HOST}/api/token/refresh/`;

/**
 * API path to fetch expense categories
 */
export const expenseCategoriesURL = `http://${process.env.REACT_APP_API_HOST}/api/data/expense/categories`;
/**
 * API path to fetch accounts
 */
export const accountsURL = `http://${process.env.REACT_APP_API_HOST}/api/data/accounts`;
/**
 * API path to fetch income sources 
 */
export const incomeSourcesURL = `http://${process.env.REACT_APP_API_HOST}/api/data/income/sources`;
/**
 * API path to fetch income types 
 */
export const incomeTypesURL = `http://${process.env.REACT_APP_API_HOST}/api/data/income/types`;

export const fixBalanceURL = `http://${process.env.REACT_APP_API_HOST}/api/data/other/fix-balance`;

export const registrationURL = `http://${process.env.REACT_APP_API_HOST}/api/auth/register/`;

export const limitsURL = `http://${process.env.REACT_APP_API_HOST}/api/data/expense/category/limits`;

/**
 * Array of account types 
 */
export const accountTypes = [
    { id: 'cash', title: 'cash' }, 
    { id: 'debet', title: 'debet' }, 
    { id: 'credit', title: 'credit' }, 
    { id: 'savings', title: 'savings' }
];