import { useEffect, useRef, useState } from 'react';
import { LineChart, BarChart } from '@mui/x-charts';
import { Button, FormLabel, Stack, Typography, Input, Table } from '@mui/joy';

import AccountsSmallTable from './AccountsSmallTable';
import { formatDate, formatFloat } from '../formatData';
import { AddCard, FormFieldStack } from './styledElements';
import { fetchRecords, getExpenseCategories, getIncomeSources } from '../api';


const INCOMES_COLOR = '#4caf50';
const EXPENSES_COLOR = '#ed6c02';


const DynamicGraph = () => {
    // Full data from DB
    const [totalData, setData] = useState({ expenses: [], incomes: [] });
    // Currently displayed data 
    const [filteredData, setFilteredData] = useState({ expenses: [], incomes: [] });
    // Dates range to filter data to display 
    const [dateRange, setDateRange] = useState({ min: null, max: null });

    // Get container width to define graphs' width
    const graphsContainer = useRef();
    const [graphsContainerWidth, setGraphsContainerWidth] = useState(400);
    useEffect(() => setGraphsContainerWidth(graphsContainer.current?.offsetWidth), [graphsContainer]);

    // Fetch initial data on element's first render 
    useEffect(() => {
        // Fetch expenses 
        fetchRecords(null, null, 'expense/records').then(data => {
            if (data instanceof Array) {
                const updatedDates = data.map(exp => ({ ...exp, user_date_time: new Date(exp.user_date_time) }));
                setData(prevState => ({...prevState, expenses: updatedDates }));
                setFilteredData(prevState => ({ ...prevState, expenses: updatedDates }));
            } else {
                setData(prevState => ({...prevState, expenses: [] }));
            };
        }).then(() => {
            // Fetch incomes 
            fetchRecords(null, null, 'income/records').then(data => {
                if (data instanceof Array) {
                    const updatedDates = data.map(inc => ({ ...inc, user_date_time: new Date(inc.user_date_time) }));
                    setData(prevState => ({...prevState, incomes: updatedDates}));
                    setFilteredData(prevState => ({ ...prevState, incomes: updatedDates }));
                } else {
                    setData(prevState => ({...prevState, incomes: []}));
                };
            });
        });
    }, []);

    // Update filtered data on every total data change
    useEffect(() => { setFilteredData(totalData) }, [totalData]);

    // Update dates range on every filtered data change, so that it displayed current range 
    useEffect(() => {
        setDateRange(prevState => {
            const dates = [...filteredData.expenses.map(e => e.user_date_time.getTime()), ...filteredData.incomes.map(e => e.user_date_time.getTime())];
            if (dates.length > 0) {
                const minDateData = Math.min(...dates), maxDateData = Math.max(...dates);
                const minDate = (prevState.min instanceof Date) ? Math.min(prevState.min.getTime(), minDateData) : minDateData
                const maxDate = (prevState.max instanceof Date) ? Math.max(prevState.max.getTime(), maxDateData) : maxDateData
                return { min: new Date(minDate).toJSON().slice(0, 10), max: new Date(maxDate).toJSON().slice(0, 10) };
            } else {
                return prevState;
            };
        });
    }, [filteredData]);

    /**
     * Updates filtered data on filters application. 
     * @param { Event } e
     */
    const handleFiltersChange = (e) => {
        e.preventDefault();
        const minDate = new Date(dateRange.min).getTime();
        const maxDate = new Date(dateRange.max).getTime();

        const newData = {
            expenses: totalData.expenses.filter(e => (e.user_date_time.getTime() >= minDate && e.user_date_time.getTime() <= maxDate)), 
            incomes: totalData.incomes.filter(e => (e.user_date_time.getTime() >= minDate && e.user_date_time.getTime() <= maxDate)), 
        };
        setFilteredData(newData);
    };

    return (
        <Stack direction={{ xs: 'column', sm: 'row' }} gap={4}>
            <Stack direction='column' gap={2} flexGrow={0.5} flexBasis={1}>
                <AddCard>
                    <FormFieldStack>
                        <FormLabel>Минимальная дата</FormLabel>
                        <Input type="date" value={ dateRange.min || '' } onChange={(e) => setDateRange(prevState => ({...prevState, min: e.target.value })) } />
                    </FormFieldStack>

                    <FormFieldStack>
                        <FormLabel>Максимальная дата</FormLabel>
                        <Input type="date" value={ dateRange.max || '' } onChange={(e) => setDateRange(prevState => ({...prevState, max: e.target.value })) } />
                    </FormFieldStack>
                    <Button type="input" onClick={handleFiltersChange}>Применить</Button>
                </AddCard>

                <AddCard>
                    <Typography component='h2'>Текущий баланс на счетах</Typography>
                    <AccountsSmallTable />
                </AddCard>

                <AddCard>
                    <Typography component='h2'>Структура трат по категориям за последние 30 дней</Typography>
                    <CategoriesTable expenses={totalData.expenses} filterDate={true} />
                </AddCard>

                <AddCard>
                    <Typography component='h2'>Структура доходов по источникам за последние 30 дней</Typography>
                    <IncomeSourcesTable incomes={totalData.incomes} filterDate={true} />
                </AddCard>
            </Stack>

            <Stack direction='row' flexWrap='wrap' gap={2} flexGrow={1} flexBasis={1} ref={graphsContainer}>
                <AddCard direction='column'>
                    <Typography component="h2">Динамика расходов и доходов</Typography>
                    <TotaldLineChart expenses={filteredData.expenses} incomes={filteredData.incomes} containerWidth={ Math.floor(graphsContainerWidth * 0.5) } />
                </AddCard>
                <AddCard direction="column">
                    <Typography component="h2">Итог за выбранный период</Typography>
                    <TotalBars expenses={filteredData.expenses} incomes={filteredData.incomes} containerWidth={ Math.floor(graphsContainerWidth * 0.4) } />
                </AddCard>
                <AddCard>
                    <Typography component='h2'>Структура трат по категориям</Typography>
                    <CategoriesTable expenses={filteredData.expenses} filterDate={false} />
                </AddCard>
                <AddCard>
                    <Typography component='h2'>Структура доходов по источникам</Typography>
                    <IncomeSourcesTable incomes={filteredData.incomes} filterDate={false} />
                </AddCard>
            </Stack>
        </Stack>
    )
};

export default DynamicGraph;


function TotaldLineChart({ expenses=[], incomes=[], containerWidth=500 }) {
    const [dataSet, setDataset] = useState([]);

    // Group data by dates and calculate total per date 
    useEffect(() => {
        if ((expenses.length > 0) || (incomes.length > 0)) {
            const expenseDates = expenses.map(exp => exp.user_date_time.toDateString());
            const incomeDates = incomes.map(inc => inc.user_date_time.toDateString());
            const uniqueDates = [...new Set([...expenseDates, ...incomeDates])].map(d => (new Date(d))).sort((a, b) => (a - b));
            
            const preparedDataset = uniqueDates.map(d => {
                const localExpenses = expenses.filter(e => e.user_date_time.toDateString() === d.toDateString());
                const localIncomes = incomes.filter(e => e.user_date_time.toDateString() === d.toDateString());
                return {
                    date: d, 
                    expenses: localExpenses.length > 0 ? localExpenses.map(e => e.value).reduce((a, b) => a + b, 0) : null,
                    incomes: localIncomes.length > 0 ? localIncomes.map(e => e.value).reduce((a, b) => a + b, 0) : null, 
                };
            });
            setDataset(preparedDataset);
        }
    }, [incomes, expenses]);

    return (
        <LineChart width={ containerWidth } height={300} dataset={dataSet}
            xAxis={[
                {
                    id: 'Date', 
                    dataKey: 'date', 
                    scaleType: 'time', 
                    valueFormatter: (date) => formatDate(date, false)
                }
            ]}
            series={[
                {
                    curve: 'linear', 
                    color: EXPENSES_COLOR,
                    id: 'Сумма расходов', 
                    label: 'Сумма расходов', 
                    dataKey: 'expenses', 
                    stack: 'total', 
                }, 
                {
                    curve: 'linear', 
                    color: INCOMES_COLOR,
                    id: 'Сумма доходов', 
                    label: 'Сумма доходов', 
                    dataKey: 'incomes', 
                    stack: 'total', 
                    showMark: true, 
                }, 
            ]}
        />
    );
};

const TotalBars = ({ expenses=[], incomes=[], containerWidth=300 }) => {
    return (
        <BarChart xAxis={[{ scaleType: 'band', data: ['Итог за период'] }]} width={ containerWidth } height={300}
            series={[
                { data: [expenses.map(e => e.value).reduce((a, b) => a + b, 0)], label: 'Расходы', color: EXPENSES_COLOR }, 
                { data: [incomes.map(e => e.value).reduce((a, b) => a + b, 0)], label: 'Доходы', color: INCOMES_COLOR } 
            ]}
        />
    )
};


const CategoriesTable = ({ expenses=[], filterDate=true }) => {
    const minDate = new Date();
    minDate.setDate(minDate.getDate() - 30);
    // Fetch categories on element's first render 
    const [categories, setCategories] = useState([]);
    useEffect(() => { getExpenseCategories().then(data => setCategories(data)) }, []);
    // Data to display
    const [updatedCategories, setUpdatedCategories] = useState([]);
        
    // Update categories with total and count values 
    useEffect(() => {
        if (categories.length > 0) {
            // Get possible expenses array 
            const possibleExpenses = filterDate ? expenses.filter(e => e.user_date_time.getTime() >= minDate.getTime()) : expenses;
            const newCategories = categories.map(cat => {
                const relatedExpenses = possibleExpenses.filter(exp => exp.category.uid === cat.uid);
                const total = (relatedExpenses.length > 0) ? relatedExpenses.map(e => e.value).reduce((a, b) => (a + b), 0) : 0;
                return { ...cat, total: total, count: relatedExpenses.length };
            }).sort((a, b) => (b.total - a.total));
            setUpdatedCategories(newCategories);
        };
    }, [expenses, categories]);

    return (
        <Table>
            <thead>
                <tr>
                    <th width="50%">Категория</th>
                    <th width="20%">Операций, шт.</th>
                    <th width="30%">Сумма</th>
                </tr>
            </thead>
            <tbody>
                { updatedCategories.length > 0 && updatedCategories.map(cat => (
                    <tr key={cat.uid}>
                        <td>{ cat.title }</td>
                        <td>{ cat.count }</td>
                        <td style={{ color: EXPENSES_COLOR }}>{ cat.total ? formatFloat(cat.total) : '' }</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
};

const IncomeSourcesTable = ({ incomes, filterDate=true }) => {
    const minDate = new Date();
    minDate.setDate(minDate.getDate() - 30);

    // Fetch sources on element's first render 
    const [sources, setSources] = useState([]);
    useEffect(() => { getIncomeSources().then(data => setSources(data)) }, []);
    // Data to display 
    const [updatedSources, setUpdatedSources] = useState([]);

    // Update data to display on incomes or sources change 
    useEffect(() => {
        if (sources.length > 0) {
            const possibleIncomes = filterDate ? incomes.filter(e => e.user_date_time.getTime() >= minDate.getTime()) : incomes;
            const newSources = sources.map(s => {
                const related = possibleIncomes.filter(e => e.source.uid === s.uid);
                const total = related.map(e => e.value).reduce((a, b) => a + b, 0);
                return { ...s,  total: total, count: related.length };
            }).filter(s => s.total > 0).sort((a, b) => (b.total - a.total));
            setUpdatedSources(newSources);
        };
    }, [incomes, sources]);

    return (
        <Table>
            <thead>
                <tr>
                    <th width="50%">Источник</th>
                    <th width="20%">Операций, шт.</th>
                    <th width="30%">Сумма</th>
                </tr>
            </thead>
            <tbody>
                { updatedSources.length > 0 && updatedSources.map(s => (
                    <tr key={s.uid}>
                        <td>{ s.title }</td>
                        <td>{ s.count }</td>
                        <td style={{ color: INCOMES_COLOR }}>{ s.total ? formatFloat(s.total) : '' }</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};
