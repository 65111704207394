import { FormLabel, Stack, Select, Input, Option, Typography, Button } from "@mui/joy"
import { useEffect, useState } from "react";
import { getIncomeTypes, postDataWithAuth } from "../api";
import { AddCard, FormFieldStack } from "./styledElements";
import { incomeSourcesURL } from "../constants";


const AddIncomeSource = () => {
    // Form data
    const [formData, setFormData] = useState({ income_type: null, title: '', involvement: 'active' });
    
    // Income types data
    const [incomeTypes, setIncomeTypes] = useState([]);
    // Fetch income types from server on element's first render 
    useEffect(() => getIncomeTypes().then(data => {if (data) setIncomeTypes(data)}), []);

    // Whether submit button is disabled or not 
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    // Change button disabled status on every form data change 
    useEffect(() => {
        let status = !formData.income_type || formData.title.length === 0 || !formData.involvement;
        setSubmitButtonDisabled(status);
    }, [formData]);

    /**
     * Handle submit: send post request to api server. If response is successful, 
     * forces page reload to show actual data. 
     * @param { Event } e 
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        postDataWithAuth(incomeSourcesURL, formData).then(result => {
            if (result) window.location.reload();
            else console.log('Error saving income source');
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <AddCard> 
                <Typography component="h1">Добавить источник</Typography>
                <Stack direction='column' gap={3}>
                    <FormFieldStack>
                        <FormLabel>Название</FormLabel>
                        <Input required placeholder="Название" value={formData.title} onChange={(e) => setFormData(prevState => ({...prevState, title: e.target.value }))} />
                    </FormFieldStack>

                    <FormFieldStack>
                        <FormLabel>Тип источника</FormLabel>
                        <Select value={formData.income_type} required onChange={(_, value) => setFormData(prevState => ({...prevState, income_type: value }))}>
                            { incomeTypes.map(t => <Option key={t.uid} value={t.uid}>{ t.group } - { t.title }</Option>) }
                        </Select>
                    </FormFieldStack>
                    
                    <FormFieldStack>
                        <FormLabel>Вовлечённость</FormLabel>
                        <Select required value={ formData.involvement } onChange={ (_, value) => setFormData(prevState => ({ ...prevState, involvement: value })) }>
                            <Option value="active">Активный</Option>
                            <Option value="passive">Пассивный</Option>
                        </Select>
                    </FormFieldStack>
                </Stack>
                <Button type="submit" disabled={submitButtonDisabled}>Добавить</Button>
            </AddCard>
        </form>
    )
};

export default AddIncomeSource;