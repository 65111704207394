import { Tabs, TabList, Tab, TabPanel, Typography, Stack, IconButton } from '@mui/joy';
import { useState } from 'react';

import AddRecord from '../components/AddRecord';
import RecordsTable from '../components/RecordsTable';
import AccountsTable from '../components/AccountsTable';
import AddExpenseCategory from '../components/AddExpenseCategory';
import AddAccount from '../components/AddAccount';
import AddIncomeSource from '../components/AddIncomeSource';
import IncomeSourcesTable from '../components/IncomeSourcesTable';
import ExpenseCategoriesTable from '../components/ExpenseCategoriesTable';
import FixBalance from '../components/FixBalance';
import { getCookie, setCookie } from '../cookies';
import { SettingsTabStack } from '../components/styledElements';
import ToggleActiveAccount from '../components/ToggleActiveAccount';
import ExpensesMap from '../components/ExpensesMap';
import Export from '../components/Export';
import DynamicGraph from '../components/DynamicGraph';
import LogoutIcon from '@mui/icons-material/Logout';
import BudgetTable from '../components/BudgetTable';
import AddLimit from '../components/AddLimit';


const DashboardPage = () => {
    const [currentTab, setCurrentTab] = useState(getCookie('mainTab') || 'records');
    const [settingsTab, setSettingsTab] = useState(getCookie('settingsTab') || 'accounts');

    const handleLogout = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        window.location.reload();
    }
    
    return (
        <>
            <Stack direction='row' padding={2} justifyContent='space-between' alignItems='center'>
                <Typography component="h1" textTransform='uppercase'>Easy money</Typography>
                <IconButton onClick={handleLogout}>
                    <LogoutIcon />
                </IconButton>
            </Stack>
            <Tabs sx={{ minHeight: '100svh' }} orientation="horizontal" defaultValue='records' value={currentTab} onChange={(_, value) => { setCookie('mainTab', value, 1); setCurrentTab(value) }}>
                <TabList>
                    <Tab value="records">Операции</Tab>
                    <Tab value="budget">Бюджет</Tab>
                    <Tab value="reports">Статистика</Tab>
                    <Tab value="map">Карта расходов</Tab>
                    <Tab value="settings">Настройки</Tab>
                </TabList>

                <TabPanel value="records">
                    <SettingsTabStack direction={{ xs: 'column-reverse', md: 'row' }}>
                        <RecordsTable />
                        <AddRecord />
                    </SettingsTabStack>
                </TabPanel>

                <TabPanel value="map">
                    <ExpensesMap />
                </TabPanel>

                <TabPanel value="reports">
                    <DynamicGraph />
                </TabPanel>

                <TabPanel value="budget">
                    <SettingsTabStack direction={{ xs: 'column-reverse', sm: 'row' }}>
                        <BudgetTable />
                        <AddLimit />
                    </SettingsTabStack>
                </TabPanel>

                <TabPanel value='settings'>
                    <Tabs orientation="horizontal" value={settingsTab} onChange={(_, value) => { setCookie('settingsTab', value, 1); setSettingsTab(value) }}>
                        <TabList>
                            <Tab value="income-sources">Источники доходов</Tab>
                            <Tab value="expense-categories">Категории расходов</Tab>
                            <Tab value="accounts">Счета</Tab>
                            <Tab value="export">Экспорт</Tab>
                        </TabList>
                        <TabPanel value="income-sources">
                            <SettingsTabStack direction={{ xs: 'column-reverse', sm: 'row' }}>
                                <IncomeSourcesTable />
                                <AddIncomeSource />
                            </SettingsTabStack>
                        </TabPanel>

                        <TabPanel value="expense-categories">
                            <SettingsTabStack direction={{ xs: 'column-reverse', sm: 'row' }}>
                                <ExpenseCategoriesTable />
                                <AddExpenseCategory />
                            </SettingsTabStack>
                        </TabPanel>

                        <TabPanel value="accounts">
                            <SettingsTabStack direction={{ xs: 'column', md: 'row' }}>
                                <AccountsTable />
                                <Stack direction="column" gap={2}>
                                    <AddAccount />
                                    <FixBalance /> 
                                </Stack>
                                <Stack direction="column" gap={2}>
                                    <ToggleActiveAccount />
                                </Stack>
                            </SettingsTabStack>
                        </TabPanel>

                        <TabPanel value="export">
                            <Export />
                        </TabPanel>
                    </Tabs>
                </TabPanel>
            </Tabs>
        </>
    )
};

export default DashboardPage;