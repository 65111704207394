import { MapContainer, TileLayer, useMapEvents, CircleMarker } from "react-leaflet";
import { useState } from "react";
import { getCookie } from "../cookies";


/**
 * Map container to point on expense location. 
 * @param {{ formData: Object, dataSetter: function }} params 
 * @returns { JSX.Element }
 */
const ExpenseLocation = ({ formData={}, dataSetter = () => {}}) => {
    // Center map on previous expense location, if present in cookies. Otherwise centers on Moscow's center
    const latCenter = parseFloat(getCookie('expense-lat-center')) || 55.749109;
    const lonCenter = parseFloat(getCookie('expense-lon-center')) || 37.626019;

    return (
        <MapContainer center={[latCenter, lonCenter]} zoom={14} scrollWheelZoom={true} style={{ height: '300px', width: '100%' }}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                url='https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png'
                subdomains='abcd'
            />
            <LocationMarker dataSetter={dataSetter} formData={formData} />
        </MapContainer>
    )
};

export default ExpenseLocation;

/**
 * Marker to show the point of click. 
 * @param {{ dataSetter: function, formData: Object }} params
 * @returns { JSX.Element }
 */
const LocationMarker = ({ dataSetter = () => {}, formData = {} }) => {
    const [position, setPosition] = useState(null)
    // Creates marker on click 
    const map = useMapEvents({
        click(e) {
            setPosition(e.latlng);
            dataSetter(prevState => ({ ...prevState, latitude: e.latlng.lat, longitude: e.latlng.lng }));
            map.flyTo(e.latlng);
        }
    })
    return position === null ? null : <CircleMarker center={{ lat: formData.latitude, lng: formData.longitude }} />;
}