import { FormLabel, Input, Stack, Typography, Select, Option, FormHelperText, Button } from "@mui/joy"
import { useEffect, useState } from "react";
import { getAccounts } from "../api";
import { AddCard, FormFieldStack } from "./styledElements";
import { accountsURL, accountTypes } from "../constants";
import { postDataWithAuth } from "../api";


/**
 * Add account form component
 * @returns { JSX.Element }
 */
const AddAccount = () => {
    // Form data that user entered 
    const [formData, setFormData] = useState({ account_type: 'cash', title: '' })
    // Existing accounts to prevent creating same titled accounts
    const [accounts, setAccounts] = useState([]);
    // States to track whether it's possible to save data 
    const [titleExists, setTitleExists] = useState(false);
    const [addButtonDisabled, setAddButtonDisabled] = useState(false);

    // Fetch existing accounts on element's first render and get their titles 
    useEffect(() => {
        getAccounts().then(data => { 
            if (data) {
                const titles = data.map(t => t.title);
                setAccounts(titles) 
            };
        });
    }, []);

    // Update title exists status on form data change
    useEffect(() => setTitleExists(accounts.includes(formData.title)), [formData.title, accounts]);
    // Update button disabled status on form data change
    useEffect(() => {
        const status = titleExists || formData.title.length === 0;
        setAddButtonDisabled(status);
    }, [formData, titleExists]);

    /**
     * Handles form submit event: prevents default and sends data to api server.
     * If response is successful, tab is reloaded to show actual data. 
     * @param { Event } e 
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        postDataWithAuth(accountsURL, formData).then(result => {
            if (result) window.location.reload();
            else console.log('Error saving account');
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <AddCard>
                <Typography component="h1">Добавить счёт</Typography>
                <Stack direction='column' gap={2}>
                    <FormFieldStack>
                        <FormLabel>Название</FormLabel>
                        <Input name="title" required value={ formData.title } placeholder="Название..." onChange={(e) => setFormData(prevState => ({ ...prevState, title: e.target.value }))}/>
                        { titleExists && <FormHelperText>Счёт с таким названием уже существует</FormHelperText> }
                    </FormFieldStack>

                    <FormFieldStack>
                        <FormLabel>Вид</FormLabel>
                        <Select required onChange={(_, value) => setFormData(prevState => ({ ...prevState, account_type: value }))} value={ formData.account_type }>
                            { accountTypes.map((atype) => (
                                <Option key={atype.id} value={atype.id}>{ atype.title }</Option>
                            ))}
                        </Select>
                    </FormFieldStack>
                </Stack>
                <Button type="submit" disabled={addButtonDisabled}>Добавить</Button>
            </AddCard>
        </form>
    )
};

export default AddAccount;