import { Button, FormLabel, Input, Typography } from "@mui/joy"
import { useEffect, useState } from "react"
import { AddCard, FormFieldStack } from "./styledElements";
import { postDataWithAuth } from "../api";
import { expenseCategoriesURL } from "../constants";


const AddExpenseCategory = () => {
    // Status whether it's possible to submit form 
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    // Form data 
    const [formData, setFormData] = useState({ title: '' });

    // Update form submit button disabled status on every form data change 
    useEffect(() => setSubmitButtonDisabled(formData.title.length === 0), [formData]);

    /**
     * Handles form submit: prevents default action, sends post request. 
     * If request is successful, reloads page to show actual data. 
     * @param { Event } e 
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        postDataWithAuth(expenseCategoriesURL, formData).then(result => {
            if (result) window.location.reload();
            else console.log('Error saving expense category');
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <AddCard>
                <Typography component="h1">Добавить категорию</Typography>
                <FormFieldStack>
                    <FormLabel>Название</FormLabel>
                    <Input value={formData.title} required placeholder="Название..." onChange={(e) => setFormData(prevState => ({ ...prevState, title: e.target.value }))} />
                </FormFieldStack>
                <Button type="submit" disabled={submitButtonDisabled}>Добавить</Button>
            </AddCard>
        </form>
    );
};

export default AddExpenseCategory;