import { Table, Stack, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { getExpenseCategories } from "../api";


const ExpenseCategoriesTable = () => {
    // Fetch expense categories on element's first render 
    const [categories, setCategories] = useState([]);
    useEffect(() => { getExpenseCategories().then(data => { if (data) setCategories(data) }) }, []);

    return (
        <Stack direction="column">
            <Typography component="h1">Существующие категории расходов</Typography>
            <Table>
                <thead>
                    <tr>
                        <th>Название категории</th>
                    </tr>
                </thead>
                <tbody>
                    { categories.length > 0 && categories.map(cat => (
                        <tr key={cat.uid}>
                            <td>{ cat.title }</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Stack>
    )
};

export default ExpenseCategoriesTable;