import { useEffect, useState } from "react"
import { AddCard, FormFieldStack } from "./styledElements";
import { Button, FormLabel, Input, Option, Select, Typography } from "@mui/joy";
import { getAccounts, postDataWithAuth } from "../api";
import { fixBalanceURL } from "../constants";

const FixBalance = () => {
    const currentDate = new Date().toISOString().slice(0, 16);
    const [accounts, setAccounts] = useState([]);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const [formData, setFormData] = useState({account: null, value: 0, user_date_time: currentDate })

    useEffect(() => { getAccounts().then(data => { if (data) setAccounts(data) }) }, [])

    useEffect(() => {
        const status = !formData.account || !formData.value || !formData.user_date_time;
        setSubmitButtonDisabled(status);
    }, [formData]);

    const handleSubmit = (e) => {
        e.preventDefault();
        postDataWithAuth(fixBalanceURL, formData).then(result => {
            if (result) window.location.reload();
            else console.log('Error saving fixed balance');
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <AddCard>
                <Typography component="h1">Уточнить баланс</Typography>
                <FormFieldStack>
                    <FormLabel>Счёт</FormLabel>
                    <Select value={formData.account} onChange={ (_, value) => setFormData(prevState => ({ ...prevState, account: value })) }>
                        { accounts.length > 0 && accounts.map(acc => <Option key={acc.uid} value={acc.uid}>{ acc.title }</Option> )}
                    </Select>
                </FormFieldStack>
                <FormFieldStack>
                    <FormLabel>Уточнённый баланс</FormLabel>
                    <Input type="number" placeholder="0.00" value={ formData.value || ''} onChange={ (e) => setFormData(prevState => ({ ...prevState, value: parseFloat(e.target.value) })) } />
                </FormFieldStack>
                <FormFieldStack>
                    <FormLabel>Дата актуальности</FormLabel>
                    <Input type="datetime-local" value={formData.user_date_time} onChange={(e) => setFormData(prevState => ({ ...prevState, user_date_time: e.target.value }))} />
                </FormFieldStack>
                <Button type="submit" disabled={submitButtonDisabled}>Сохранить</Button>
            </AddCard>
        </form>
    )
};

export default FixBalance;