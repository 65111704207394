import { getAccounts } from "../api";
import { useEffect, useState } from "react";
import { Table, Typography, Stack, Switch } from "@mui/joy";
import { formatFloat } from "../formatData";


/**
 * Accounts table to display in settings. 
 * @returns { JSX.Element }
 */
const AccountsTable = () => {
    // Data to display
    const [accounts, setAccounts] = useState([]);
    // Fetch data on element's first render 
    useEffect(() => {
        getAccounts(false).then(data => { if (data) setAccounts(data); })
    }, []);

    return (
        <Stack direction="column">
            <Typography component="h1">Существующие счета</Typography>
            <Table sx={{ flexGrow: 0, }}>
                <thead>
                    <tr>
                        <th>Название</th>
                        <th>Вид</th>
                        <th>Баланс</th>
                        <th>Состояние</th>
                    </tr>
                </thead>
                <tbody>
                    { accounts.length > 0 && accounts.map(acc => (
                        <tr key={acc.uid}>
                            <td width="45%">{ acc.title }</td>
                            <td width="20%">{ acc.account_type }</td>
                            <td width="35%"><span>{ formatFloat(acc.balance) }</span></td>
                            <td><Switch checked={ acc.active } disabled /></td>
                        </tr>
                    )) }
                </tbody>
            </Table>
        </Stack>
    )
};

export default AccountsTable;
