import { Box, Stack, Table } from "@mui/joy"
import { useEffect, useState } from "react"
import { deleteRecord, getLimits } from "../api";
import { formatFloat, barColor } from "../formatData";
import { IconButton, Typography } from "@mui/material";
import DeleteOutlineOutlined from "@mui/icons-material/DeleteOutlineOutlined";
import BudgetSummary from "./BudgetSummary";

const BudgetTable = () => {
    const [limits, setLimits] = useState([])
    useEffect(() => { getLimits().then(data => setLimits(data)) }, []);

    const handleDelete = (uid) => {
        deleteRecord(`http://${process.env.REACT_APP_API_HOST}/api/data/expense/category/limits/${uid}`).then(result => {
            if (result) window.location.reload();
            else console.log('Error deleting limit');
        });
    };

    return (
        <Stack direction="column" gap={2}>
            <BudgetSummary />
            <Typography component="h1">Установленные цели</Typography>
            <Table>
                <thead>
                    <tr>
                        <th>Категория</th>
                        <th>Установленная сумма</th>
                        <th>Остаток на балансе</th>
                        <th>Действия</th>
                    </tr>
                </thead>
                <tbody>
                    { limits.map(limit => {
                        const ratio = Math.max(Math.floor((limit.current_balance / limit.cumulative_value) * 100), 1);
                        const currentBarColor = barColor(ratio);
                        return (
                            <tr key={limit.uid}>
                                <td width="25%">{ limit.category.title }</td>
                                <td width="20%">{ formatFloat(limit.cumulative_value) }</td>
                                <td width="50%">
                                    { formatFloat(limit.current_balance) }
                                    <Box sx={{ height: '8px', width: `${ratio}%`, backgroundColor: currentBarColor, borderRadius: '4px' }}/>
                                </td>
                                <td width="5%">
                                    <IconButton onClick={() => handleDelete(limit.uid)}>
                                        <DeleteOutlineOutlined />
                                    </IconButton>
                                </td>
                            </tr>
                    )})}
                </tbody>
            </Table>
        </Stack>
    )
};

export default BudgetTable;
