import { Table, Stack, Typography, IconButton, Tooltip, Snackbar } from "@mui/joy"
import { useEffect, useState } from "react";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { fetchRecords, deleteRecord } from "../api";
import { formatFloat, formatDate } from "../formatData";
import AccountsSmallTable from "./AccountsSmallTable";
import BudgetSummary from "./BudgetSummary";


const RecordsTable = () => {
    const [showDeleteError, setShowDeleteError] = useState(false);
    const [records, setRecords] = useState([]);
    useEffect(() => { getRecords().then(data => { setRecords(data.records) }) }, []);

    const handleDeleteItem = (recordType, uid) => {
        let requestUrl = deleteRequestUrl(recordType, uid)
        if (!requestUrl) return false;
        if (!window.confirm("Операция удалится навсегда, её нельзя будет восстановить")) return;
        deleteRecord(requestUrl).then(result => {
            if (result) window.location.reload();
            else setShowDeleteError(true);
        })
    };

    return (
        <Stack direction="column" gap={3}>
            <AccountsSmallTable />
            <BudgetSummary />
            
            { (records && (records.length === 0)) ? <Typography>Нет записей...</Typography> : 
                <>
                <Stack direction='column' gap={1}>
                    <Typography component="h1">История операций</Typography>
                    <Typography>{ formatDate(records[records.length - 1].user_date_time, false) } - { formatDate(records[0].user_date_time, false) }</Typography>
                </Stack>
                <Table>
                    <thead>
                        <tr>
                            <th>Дата</th>
                            <th>Счета</th>
                            <th>Описание операции</th>
                            <th>Сумма</th>
                            <th>Геопозиция</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    { records.map(record => (
                        <tr key={record.uid}>
                            <td width="20%" data-value={record.user_date_time.toJSON()}>{ formatDate(record.user_date_time) }</td>
                            <td width="15%">{ accountsColumnText(record) }</td>
                            <td width="30%">{ descriptionColumnText(record) }</td>
                            <td width="20%" data-value={ record.value }>{ recordValue(record) }</td>
                            <td width="5%">{ (record.latitude && record.longitude) ? 
                                <Stack direction='row' gap={0.5}>
                                    <Typography component="span">{ record.latitude.toFixed(4)}</Typography>
                                    <Typography component="span">{ record.longitude.toFixed(4)}</Typography>
                                </Stack>
                                : <Typography>-</Typography>}
                            </td>
                            <td width="5%%">
                                <Tooltip title="Удалить операцию" variant="outlined" color="danger" size="sm" placement="right" arrow>
                                    <IconButton onClick={() => handleDeleteItem(record.recordType, record.uid)} color='danger'>
                                        <DeleteOutlineOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            </td>
                        </tr>
                    ))}    
                    </tbody>            
                </Table> 
                </>
            }
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={showDeleteError} color='danger' variant="outlined" size="sm" onClose={() => setShowDeleteError(false)} autoHideDuration={3000}>
                Не удалось удалить операцию, попробуйте позже
            </Snackbar>
        </Stack>
    )
};

export default RecordsTable;


function deleteRequestUrl(recordType, uid) {
    switch (recordType) {
        case 'expense':
            return `http://${process.env.REACT_APP_API_HOST}/api/data/expense/records/${uid}`;
        case 'income':
            return `http://${process.env.REACT_APP_API_HOST}/api/data/income/records/${uid}`;
        case 'transfer': 
            return `http://${process.env.REACT_APP_API_HOST}/api/data/other/transfer/${uid}`;
        case 'fix-balance':
            return `http://${process.env.REACT_APP_API_HOST}/api/data/other/fix-balance/${uid}`;
        default:
            return 
    };
};


function accountsColumnText(record) {
    switch (record.recordType) {
        case 'expense':
            return record.account.title;
        case 'income':
            return record.account.title;
        case 'fix-balance':
            return record.account.title;
        case 'transfer':
            return `${record.from_account.title} -> ${record.to_account.title}`;
        default:
            return record.account.title;
    }
}

function descriptionColumnText(record) {
    switch (record.recordType) {
        case 'expense':
            return record.category.title;
        case 'transfer':
            return 'Перевод между счетами';
        case 'income':
            return record.source.title;
        case 'fix-balance': 
            return 'Уточнение баланса на счёте';
        default:
            return 'Операция по счётом';
    }
};


function recordValue(record) {
    let formattedValue = formatFloat(record.value);
    switch (record.recordType) {
        case 'expense':
            return `-${formattedValue}`;
        case 'income': 
            return `+${formattedValue}`;
        default:
            return formattedValue;
    }
};


async function getRecords(minDate=null, maxDate=null) {
    const expenses = await fetchRecords(minDate, maxDate, 'expense/records');
    const incomes = await fetchRecords(minDate, maxDate, 'income/records');
    const transfers = await fetchRecords(minDate, maxDate, 'other/transfer');
    const fixBalances = await fetchRecords(minDate, maxDate, 'other/fix-balance');
    
    let records = [
        ...expenses.map((record) => ({...record, recordType: 'expense' })), 
        ...incomes.map((record) => ({...record, recordType: 'income' })), 
        ...transfers.map((record) => ({...record, recordType: 'transfer' })), 
        ...fixBalances.map((record) => ({...record, recordType: 'fix-balance' }))
    ];

    records = records.map(rec => {
        const date = new Date(rec.user_date_time);
        rec.user_date_time = date;
        return rec;
    });
    records = records.sort((a, b) => (b.user_date_time - a.user_date_time)); 

    return {
        records: records 
    };
};