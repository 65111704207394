import { Stack, Typography, Input, Tabs, TabList, Tab, TabPanel, Select, Option, FormLabel, FormHelperText, Button } from "@mui/joy"
import { useEffect, useState } from "react";
import { getExpenseCategories, getAccounts, getIncomeSources } from "../api";
import { AddCard, FormFieldStack } from "./styledElements";
import ExpenseLocation from "./ExpenseLocation";
import { setCookie } from "../cookies";
import { postDataWithAuth } from "../api";


/**
 * Block to add expense / income / transfer record. Contains of common fields and tabs, that include
 * record-specific fields. 
 * @returns { JSX.Element }
 */
const AddRecord = () => {
    /**
     * Returns default form data object. Contains value = 0 and user_date_time = current date and time. 
     * @returns {{ value: number, user_date_time: Date }} Default data to fill the form. 
     */
    const defaultFormData = () => ({
        value: 0,
        user_date_time: new Date().toISOString().slice(0, 16)
    });
    // Entered into form data holder
    const [formData, setFormData] = useState(defaultFormData());
    // Defines whether there is enough data to add new record
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    // Defines expected form data for submitButtonDisabled status and target fetch url
    const [currentTab, setCurrentTab] = useState('expense');

    // Update submit button status on every form data content change
    // Checks whether common fields' and record-specific fields' inputs are present and meet their conditions
    useEffect(() => {
        let basicStatus = !formData.value || formData.value === 0 || !formData.user_date_time;
        let specialStatus;
        switch (currentTab) {
            case 'expense':
                specialStatus = !formData.account || !formData.category;
                break;
            case 'income':
                specialStatus = !formData.account || !formData.source;
                break;
            case 'transfer':
                specialStatus = !formData.from_account || !formData.to_account;
                break;
            default:
                specialStatus = false;
                break;
        };
        setSubmitButtonDisabled(basicStatus || specialStatus);
    }, [formData, currentTab]);

    /**
     * Handles form data submit. 
     * 
     * Defines request url depending on currently active tab, then sends post request to api server.
     * If request is successful, reloads page to show updates. Otherwise, logs error to console. 
     * @param { Event } e 
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        let url;
        switch (currentTab) {
            case 'expense': 
                url = `http://${process.env.REACT_APP_API_HOST}/api/data/expense/records`;
                break;
            case 'income': 
                url = `http://${process.env.REACT_APP_API_HOST}/api/data/income/records`;
                break;
            case 'transfer':
                url = `http://${process.env.REACT_APP_API_HOST}/api/data/other/transfer`;
                break;
        };
        if (url) {
            const updatedFormData = {
                ...formData, 
                user_date_time: new Date(formData.user_date_time)
            };
            
            postDataWithAuth(url, updatedFormData).then(result => {
                setCookie('expense-lat-center', updatedFormData.latitude, 10);
                setCookie('expense-lon-center', updatedFormData.longitude, 10);
                if (result) window.location.reload();
                else console.log('Error adding record');
            });
        };
    };

    /**
     * Updates amount value change in form data. 
     * Removes all symbols, besides digits and dots, and sets new formData object. 
     * @param { string } value Raw value from input field. 
     */
    const handleAmountChange = (value) => {
        let newValue = value.replace(/[^\d\.]/g, '');
        if (newValue === '') newValue = 0;
        setFormData(prevState => ({
            ...prevState,
            value: Math.abs(parseFloat(newValue))
        }));
    };

    return (
        <form onSubmit={handleSubmit}>
            <AddCard>
                <Typography component="h1">Добавить операцию</Typography>
                <FormFieldStack>
                    <FormLabel>Сумма</FormLabel>
                    <Input name="value" value={formData['value'] > 0 ? formData['value'] : ''} placeholder="Сумма" type="number" onChange={(e) => handleAmountChange(e.target.value)} required/>
                </FormFieldStack>
                <FormFieldStack>
                    <FormLabel>Дата платежа</FormLabel>
                    <Input name="date" value={formData['user_date_time']} type="datetime-local" onChange={(e) => setFormData(prevState => ({ ...prevState, user_date_time: e.target.value }))} required/>
                </FormFieldStack>

                <Stack direction="column" width="100%">
                    <Tabs value={currentTab} onChange={(_, value) => {setCurrentTab(value); setFormData(defaultFormData())}}>
                        <TabList>
                            <Tab value="expense">Расход</Tab>
                            <Tab value="income">Доход</Tab>
                            <Tab value="transfer">Перевод</Tab>
                        </TabList>
                        <ExpenseTabPanel dataSetter={setFormData} formData={formData} />
                        <IncomeTabPanel dataSetter={setFormData} />
                        <TransferTabPanel dataSetter={setFormData} />
                    </Tabs>
                    <Button type="submit" disabled={submitButtonDisabled}>Добавить</Button>
                </Stack>
            </AddCard>
        </form>
    )
};

export default AddRecord;


/**
 * Expense related form inputs. 
 * 
 * Includes account and expense category selection, and map container to point on expense location.
 * @param {{ dataSetter: function, formData: { string: any } }} params  
 * @returns { JSX.Element }
 */
const ExpenseTabPanel = ({ dataSetter = () => {}, formData={} }) => {
    // Data for selectors 
    const [categories, setCategories] = useState(null);
    const [accounts, setAccounts] = useState(null);
    // Fetch data on element first render 
    useEffect(() => {
        getExpenseCategories().then(data => { if (data) setCategories(data) });
        getAccounts().then(data => { if (data) setAccounts(data) });
    }, [])

    return (
        <TabPanel value="expense">
            <Stack direction="column" gap={2}>
                <FormFieldStack>
                    <FormLabel>Со счёта...</FormLabel>
                    <Select placeholder="Счёт..." disabled={!accounts || accounts.length === 0} required onChange={(_, value) => dataSetter(prevState => ({ ...prevState, account: value }))}>
                        { accounts && accounts.map(acc => (<Option value={acc.uid} key={acc.uid}>{acc.title}</Option>))}
                    </Select>
                </FormFieldStack>

                <FormFieldStack>
                    <FormLabel>Категория</FormLabel>
                    <Select placeholder="Категория..." disabled={!categories || categories.length === 0} required onChange={(_, value) => dataSetter(prevState => ({ ...prevState, category: value }))}>
                        { categories && categories.map(cat => (<Option value={cat.uid} key={cat.uid}>{cat.title}</Option>))}
                    </Select>
                    { (categories && categories.length === 0)  && <FormHelperText>Создайте хотя бы 1 категорию, чтобы добавлять записи</FormHelperText> }
                </FormFieldStack>

                <FormFieldStack minWidth='400px'>
                    <FormLabel>Локация</FormLabel>
                    <Stack direction='column' gap={2}>
                        <Stack direction='column' gap={2}>
                            <Input type="number" disabled value={formData.latitude || ''} placeholder="Широта"/>
                            <Input type="number" disabled value={formData.longitude || ''} placeholder="Долгота" />
                            <Button onClick={() => dataSetter(prevState => ({...prevState, latitude: null, longitude: null }))} variant="outlined" color='danger' disabled={ !formData.latitude && !formData.longitude }>
                                Очистить
                            </Button>
                        </Stack>
                        <ExpenseLocation dataSetter={dataSetter} formData={formData} />
                    </Stack>
                </FormFieldStack>

            </Stack>
        </TabPanel>
    )
};

/**
 * Incomes related form fields. 
 * Includes account, income source fields. 
 * @param {{ dataSetter: function }} params 
 * @returns { JSX.Element }
 */
const IncomeTabPanel = ({ dataSetter }) => {
    // Form selectors data
    const [accounts, setAccounts] = useState(null);
    const [sources, setSources] = useState(null);
    // Fetch data on element first render 
    useEffect(() => {
        getAccounts().then(data => { if (data) setAccounts(data) })
        getIncomeSources().then(data => { if (data) setSources(data) })
    }, [])

    return (
        <TabPanel value="income">
            <Stack direction="column" gap={2}>
                <FormFieldStack>
                    <FormLabel>На счёт...</FormLabel>
                    <Select placeholder="Счёт..." disabled={!accounts || accounts.length === 0} required onChange={(_, value) => dataSetter(prevState => ({ ...prevState, account: value }))}>
                        { accounts && accounts.map(acc => (<Option value={acc.uid} key={acc.uid}>{acc.title}</Option>))}
                    </Select>
                </FormFieldStack>

                <FormFieldStack>
                    <FormLabel>Источник</FormLabel>
                    <Select placeholder="Источник..." disabled={!accounts || accounts.length === 0} required onChange={(_, value) => dataSetter(prevState => ({ ...prevState, source: value }))}>
                        { sources && sources.map(s => (<Option value={s.uid} key={s.uid}>{s.title}</Option>))}
                    </Select>
                    { (sources && sources.length === 0)  && <FormHelperText>Создайте хотя бы 1 источник, чтобы добавлять записи</FormHelperText> }
                </FormFieldStack>
            </Stack>
        </TabPanel>
    )
};

/**
 * Transfer-specific form fields. 
 * @param {{ dataSetter: function }} params 
 * @returns { JSX.Element }
 */
const TransferTabPanel = ({ dataSetter }) => {
    // Form selectors data 
    const [accounts, setAccounts] = useState(null);
    // Fetch data on element's first render
    useEffect(() => {
        getAccounts().then(data => { if (data) setAccounts(data) });
    }, [])

    return (
        <TabPanel value="transfer">
            <Stack direction="column" gap={2}>
                <FormFieldStack>
                    <FormLabel>Со счёта...</FormLabel>
                    <Select 
                        placeholder="Счёт..." 
                        disabled={!accounts || accounts.length === 0} 
                        required 
                        onChange={(_, value) => dataSetter(prevState => ({ ...prevState, from_account: value }))}
                    >
                        { accounts && accounts.map(acc => (<Option value={acc.uid} key={acc.uid}>{acc.title}</Option>))}
                    </Select>
                </FormFieldStack>

                <FormFieldStack>
                    <FormLabel>На счёт...</FormLabel>
                    <Select placeholder="Счёт..." disabled={!accounts || accounts.length === 0} required onChange={(_, value) => dataSetter(prevState => ({ ...prevState, to_account: value }))}>
                        { accounts && accounts.map(acc => (<Option value={acc.uid} key={acc.uid}>{acc.title}</Option>))}
                    </Select>
                </FormFieldStack>
            </Stack>
        </TabPanel>
    )
};