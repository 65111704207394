export function formatFloat(value) {
    try {
        let parts = value.toFixed(2).toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        return parts.join(".");
    } catch (error) {
        console.log('invalid number');
        return '';
    };
};

export function formatDate(date, includeTime=true) {
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
    let day = String(date.getDate()).padStart(2, '0');

    if (includeTime) {
        let hours = String(date.getHours()).padStart(2, '0');
        let minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}.${month}.${year} ${hours}:${minutes}`;
    } else {
        return `${day}.${month}.${year}`;
    }
};

export function formatDateRequests(date) {
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
    let day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export const barColor = (value) => {
    const red = Math.max(0, 255 * (1 - value / 100));
    const green = Math.max(0, 255 * (value / 100));
    return `rgb(${red}, ${green}, 0)`;
};