import { Button, FormLabel, Option, Select, Typography } from "@mui/joy";
import { AddCard, FormFieldStack } from "./styledElements"
import { useState, useEffect } from "react";
import { getAccounts, patchDataWidthAuth } from "../api";


const ToggleActiveAccount = () => {
    const [accounts, setAccounts] = useState([]);
    const [chosenAccount, setChosenAccount] = useState(null);
    const [newStatus, setNewStatus] = useState(null);

    useEffect(() => {
        getAccounts(false)
            .then(data => { if (data) setAccounts(data); })
    }, []);

    useEffect(() => {
        let currentStatus;
        for (let account of accounts) {
            if (account.uid === chosenAccount) {
                currentStatus = account.active;
                break;
            };
        };
        setNewStatus(!currentStatus);
    }, [chosenAccount]);

    const handleSubmit = (e) => {
        e.preventDefault();
        patchDataWidthAuth(`http://${process.env.REACT_APP_API_HOST}/api/data/accounts/${chosenAccount}`, { active: newStatus }).then( result => {
            if (result) window.location.reload();
            else console.log('Error updating account');
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <AddCard>
                <Typography component="h1">Изменить статус счёта</Typography>
                <FormFieldStack>
                    <FormLabel>Счёт</FormLabel>
                    <Select value={chosenAccount} onChange={ (_, value) => setChosenAccount(value) }>
                        { accounts.length > 0 && accounts.map(acc => (
                            <Option key={acc.uid} value={acc.uid}>{ acc.title }</Option>
                        ))}
                    </Select>
                </FormFieldStack>
                { chosenAccount && <Button type="submit" color={ newStatus ? 'success' : 'danger' }>{newStatus ? 'Активировать' : 'Деактивировать'}</Button> }
            </AddCard>
        </form>
    )
};

export default ToggleActiveAccount;