import { Stack, Button, Typography } from "@mui/joy"
import { AddCard } from "./styledElements";
import { useEffect, useState } from "react";
import { fetchRecords } from "../api";

const Export = () => {
    return (
        <Stack direction="row" gap={2} flexWrap='wrap'>
            <ExportExpenses />
            <ExportIncomes />
            <ExportTransfers />
            <ExportFixBalance />
        </Stack>
    )
};

export default Export;


const ExportExpenses = () => {
    const [expenses, setExpenses] = useState([]);
    const [downloadAvailable, setDownloadAvailable] = useState(true);
    useEffect(() => {
        fetchRecords(null, null, 'expense/records').then(data => setExpenses(data));
    }, []);

    const handleDownload = () => {
        setDownloadAvailable(false);
        const csvContent = 'Дата,Сумма,Категория,Счёт,Широта,Долгота\n' + expenses.map(e => [`"${e.user_date_time}"`, e.value, `"${e.category.title}"`, `"${e.account.title}"`, e.latitude || '', e.longitude || ''].join(',')).join('\n');
        downloadCSV(csvContent, 'expenses.csv');
        setDownloadAvailable(true);
    };

    return (
        <AddCard>
            <Typography component="h1">Экспорт расходов</Typography>
            <Typography>Найдено записей: { expenses.length }</Typography>
            <Button onClick={handleDownload} disabled={ !downloadAvailable || (expenses.length === 0) }>
                Скачать расходы
            </Button>
        </AddCard>
    )
};

const ExportIncomes = () => {
    const [incomes, setIncomes] = useState([]);
    const [downloadAvailable, setDownloadAvailable] = useState(true);
    useEffect(() => {
        fetchRecords(null, null, 'income/records').then(data => setIncomes(data));
    }, []);

    const handleDownload = () => {
        setDownloadAvailable(false);
        const csvContent = 'Дата,Сумма,Источник,Счёт\n' + incomes.map(e => [`"${e.user_date_time}"`, e.value, `"${e.source.title}"`, `"${e.account.title}"`].join(',')).join('\n');
        downloadCSV(csvContent, 'incomes.csv');
        setDownloadAvailable(true);
    };

    return (
        <AddCard>
            <Typography component="h1">Экспорт доходов</Typography>
            <Typography>Найдено записей: { incomes.length }</Typography>
            <Button onClick={handleDownload} disabled={ !downloadAvailable || (incomes.length === 0) }>
                Скачать доходы
            </Button>
        </AddCard>
    )
};

const ExportTransfers = () => {
    const [transfers, setTransfers] = useState([]);
    const [downloadAvailable, setDownloadAvailable] = useState(true);
    useEffect(() => {
        fetchRecords(null, null, 'other/transfer').then(data => setTransfers(data));
    }, []);

    const handleDownload = () => {
        setDownloadAvailable(false);
        const csvContent = 'Дата,Сумма,Со счёта,На счёт\n' + transfers.map(e => [`"${e.user_date_time}"`, e.value, `"${e.from_account.title}"`, `"${e.to_account.title}"`].join(',')).join('\n');
        downloadCSV(csvContent, 'transfers.csv');
        setDownloadAvailable(true);
    };

    return (
        <AddCard>
            <Typography component="h1">Экспорт переводов</Typography>
            <Typography>Найдено записей: { transfers.length }</Typography>
            <Button onClick={handleDownload} disabled={ !downloadAvailable || (transfers.length === 0) }>
                Скачать переводы
            </Button>
        </AddCard>
    )
};

const ExportFixBalance = () => {
    const [fixes, setFixes] = useState([]);
    const [downloadAvailable, setDownloadAvailable] = useState(true);
    useEffect(() => {
        fetchRecords(null, null, 'other/fix-balance').then(data => setFixes(data));
    }, []);

    const handleDownload = () => {
        setDownloadAvailable(false);
        const csvContent = 'Дата,Сумма,Счёт\n' + fixes.map(e => [`"${e.user_date_time}"`, e.value, `"${e.account.title}"`].join(',')).join('\n');
        downloadCSV(csvContent, 'balance_fixes.csv');
        setDownloadAvailable(true);
    };

    return (
        <AddCard>
            <Typography component="h1">Экспорт уточнений баланса</Typography>
            <Typography>Найдено записей: { fixes.length }</Typography>
            <Button onClick={handleDownload} disabled={ !downloadAvailable || (fixes.length === 0) }>
                Скачать уточнения баланса
            </Button>
        </AddCard>
    )
}


function downloadCSV(csvContent, filename) {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    
    link.click();
    
    document.body.removeChild(link);
};