import { jwtDecode } from "jwt-decode";
import { getTokenURL, refreshTokenURL } from "./constants";


/**
 * Authorize user with the given credentials. 
 * @param {string} username - Username (email)
 * @param {string} password - Password
 * @returns { Promise< { access: string, refresh: string } | null > } JWT tokens, if authentication is successful.
 */
export async function getToken(username='', password='') {
    try {
        const response = await fetch(getTokenURL, {
            method: 'POST', 
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({email: username, password: password})
        });
        if (response.ok) {
            const data = await response.json();
            return data;
        };
    } catch (error) {
        console.log('Error fetching response');
    };
};

/**
 * Refreshes access token using stored refresh token
 * 
 * If refresh token is also invalid or missing in local storage, authorization fails. 
 * Otherwise, API server is expected to return new access token.
 * @returns { Promise<boolean> } Auth status. 
 */
export async function forseRefreshToken() {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
        const response = await fetch(refreshTokenURL, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ refresh: refreshToken }),
        });
        if (response.ok) {
            const tokens = await response.json();
            localStorage.setItem('accessToken', tokens['access']);
            return true;
        };
    };
    return false;
};

/**
 * Checks access and refresh tokens. If decoded acccess token is still valid, 
 * no other checks are performed. Otherwise, chechs whether refresh token is still valid.
 * If so, forces access token refresh. 
 * @returns { Promise<boolean> } User auth status. 
 */
export async function getAuthStatus() { 
    // Check if access token is still valid 
    const accessTokenValue = localStorage.getItem('accessToken');
    if (accessTokenValue) { 
        try {
            const decodedAccessToken = jwtDecode(accessTokenValue);
            if (decodedAccessToken.exp * 1000 > Date.now()) {
                return true;
            } else {
                localStorage.removeItem('accessToken');
            };
        } catch (error) {
            localStorage.removeItem('accessToken');
            console.log('Error decoding token');
        };
    };

    // If access token is invalid, check if refresh token is still valid
    const refreshTokenValue = localStorage.getItem('refreshToken');
    if (refreshTokenValue) {
        try {
            const decodedRefreshToken = jwtDecode(refreshTokenValue);
            if (decodedRefreshToken.exp * 1000 > Date.now()) {
                const refreshResult = await forseRefreshToken(); 
                return refreshResult;
            } else {
                localStorage.removeItem('refreshToken');
            }
        } catch (error) {
            localStorage.removeItem('refreshToken');
            console.log('Error decoding token');
        };
    };
    return false;
};
