import { formatDateRequests } from "./formatData";
import { getAuthStatus } from "./auth";
import { accountsURL, expenseCategoriesURL, incomeSourcesURL, incomeTypesURL, limitsURL } from "./constants";


/**
 * Fetch expenses / incomes / transfers / fix-balances 
 * @param { Date | null } minDate Latest date 
 * @param { Date | null } maxDate Earliest date 
 * @param { string } path URL path to add after '.../data/'
 * @returns { Promise<> }
 */
export async function fetchRecords(minDate=null, maxDate=null, path='') {
    const params = new URLSearchParams();
    if (minDate instanceof Date) params.append('user_date_time__gte', formatDateRequests(minDate));
    if (maxDate instanceof Date) params.append('user_date_time__lt', formatDateRequests(maxDate));

    const paramsString = params.toString(); 
    const requestURL = `http://${process.env.REACT_APP_API_HOST}/api/data/${path}?${paramsString}`;

    const responseData = await getDataWithAuth(requestURL);
    return responseData;
};

/**
 * Fetch expense categories
 * @returns { Promise<> }
 */
export async function getExpenseCategories() {
    const responseData = await getDataWithAuth(expenseCategoriesURL);
    return responseData;
};

/**
 * Fetch accounts
 * @param { boolean } activeOnly - Whether to return only active account or all.
 * @returns { Promise<> }
 */
export async function getAccounts(activeOnly=true) {
    const responseData = await getDataWithAuth(accountsURL);
    if (responseData && activeOnly) {
        return responseData.filter(d => d.active);
    };
    return responseData;
};

export async function getLimits() {
    const responseData = await getDataWithAuth(limitsURL);
    return responseData
};

/**
 * Fetch income sources
 * @returns { Promise<> } 
 */
export async function getIncomeSources() {
    const responseData = await getDataWithAuth(incomeSourcesURL);
    return responseData;
};

/**
 * Fetch income types
 * @returns { Promise<> }
 */
export async function getIncomeTypes() {
    const responseData = await getDataWithAuth(incomeTypesURL);
    return responseData;
};

/**
 * Sends delete request
 * 
 * First makes sure that user has valid access token, then sends the request
 * and returnes status whether request is successful. If user is not authenticated, 
 * forces tab reload to display login page. 
 * 
 * @param { string } requestUrl - Request URL.
 * @returns { Promise<boolean> } Response status == 204. 
 */
export async function deleteRecord(requestUrl) {
    const authStatus = await getAuthStatus();
    if (authStatus) {
        const response = await fetch(requestUrl, {
            method: 'DELETE', 
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`, 
                'Content-Type': 'application/json', 
            }
        });
        return response.status === 204;
    } else {
        window.location.reload();
    };
    return false;
};

/**
 * Patch request.
 * @param { string } requestUrl - Request URL.
 * @param { Object } body - Request body.
 * @returns { Promise<boolean> }
 */
export async function patchDataWidthAuth(requestUrl, body={}) {
    const authStatus = await getAuthStatus();
    if (authStatus) {
        const response = await fetch(requestUrl, {
            method: 'PATCH', 
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`, 
                'Content-Type': 'application/json', 
            }, 
            body: JSON.stringify(body)
        });
        if (response.ok) {
            return true;
        } else {
            return false;
        }
    } else {
        window.location.reload();
    };
}


/**
 * Post data to api server and return response status.
 * 
 * Verifies, whether user is authorized; if so, sends post request with provided
 * body as jsonified string. Otherwises, forces tab reload to display login window.
 * 
 * @param {string} requestUrl - Request URL.
 * @param {Object} body - Request body.
 * @returns { Promise<boolean> }
 */
export async function postDataWithAuth(requestUrl='', body={}) {
    const authStatus = await getAuthStatus();
    if (authStatus) {
        const response = await fetch(requestUrl, {
            method: 'POST', 
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`, 
                'Content-Type': 'application/json', 
            }, 
            body: JSON.stringify(body)
        });
        if (response.ok) {
            return true;
        } else {
            return false;
        }
    } else {
        window.location.reload();
    };
};


/**
 * Gets data from url and returns result, if successful
 * 
 * Before fetching data, verifies that user is authenticated and has valid 
 * access token. If so, sends GET-request and returns response json data. 
 * Otherwise, forces tab reload to display login page. 
 * @param { string } url - URL to request. 
 * @returns { Promise<[Object] | null> } Response data. 
 */
async function getDataWithAuth(url) {
    const authStatus = await getAuthStatus();
    if (authStatus) {
        const response = await fetch(url, {
            method: 'GET', 
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`, 
                'Content-Type': 'application/json', 
            }
        });
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            return null;
        };
    } else {
        // If user cannot be authenticated, 
        // force window reload to open login page
        window.location.reload();
    }
}