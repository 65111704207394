import { Stack, Table, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { getIncomeSources } from "../api";


const IncomeSourcesTable = () => {
    // Fetch sources on element's first render 
    const [sources, setSources] = useState([]);
    useEffect(() => { getIncomeSources().then(data => { if (data) setSources(data) }) }, []);

    return (
        <Stack direction="column">
            <Typography component="h1">Существующие источники дохода</Typography>
                <Table>
                    <thead>
                        <tr>
                            <th>Тип дохода</th>
                            <th>Название источника</th>
                            <th>Вовлечённость</th>
                        </tr>
                    </thead>
                    <tbody>
                        { sources.length > 0 && sources.map(s => (
                            <tr key={s.uid}>
                                <td>{ s.income_type.title }</td>
                                <td>{ s.title }</td>
                                <td>{ s.involvement }</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
        </Stack>
    )
};

export default IncomeSourcesTable;