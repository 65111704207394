import { Input, Stack, Typography, Snackbar, Link, Button } from "@mui/joy";
import { useState } from "react";

import { getToken } from "../auth";
import { postDataWithAuth } from "../api";
import { registrationURL } from "../constants";


const LoginPage = () => {
    const [registered, setRegistered] = useState(true);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showError, setShowError] = useState(false);
    const [showEmail, setShowEmail] = useState(false);
    const [inputAvailable, setInputAvailable] = useState(true);

    const handleSubmit = (e) => {
        e.preventDefault();
        setInputAvailable(false);
        setShowError(false);
        if (isValidEmail(email) && (password !== '')) {
            if (registered) {
                getToken(email, password)
                .then(tokens => {
                    if (tokens) {
                        localStorage.setItem('accessToken', tokens['access']);
                        localStorage.setItem('refreshToken', tokens['refresh']);
                        window.location.reload();
                    } else {
                        setShowError(true);
                        setInputAvailable(true);
                    };
                })
            } else {
                fetch(registrationURL, {
                    method: 'POST', 
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ email: email, password: password })
                }).then(response => {
                    if (response.ok) {
                        setShowEmail(true);
                    } else {
                        setShowError(true);
                        setInputAvailable(true);
                        console.log('Error in response:', response.statusText);
                    }
                }).catch(error => {
                    console.log('Error fetching response:', error);
                });
            };
        };
    };

    return (
        <Stack direction="column" sx={{ maxWidth: 300, marginLeft: 'auto', marginRight: 'auto', marginTop: 5, marginBottom: 5, rowGap: 1 }}>
            <Typography component="h1">{ registered ? 'Вход в аккаунт' : 'Создать аккаунт' }</Typography>
            <form onSubmit={handleSubmit}>
                <Stack direction="column" autoComplete="false" rowGap={1}>
                    <Input placeholder="Почта" type="email" name="email" value={email} required onChange={(e) => setEmail(e.target.value)} />
                    <Input placeholder="Пароль" type="password" name="password" value={password} required onChange={(e) => setPassword(e.target.value)} />
                    <Button type="submit" sx={{ marginTop: 2 }} disabled={!inputAvailable}>{ registered ? 'Войти' : 'Зарегистрироваться' }</Button>
                </Stack>
            </form>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={showError} color="danger" variant="solid" autoHideDuration={500} onClose={() => setShowError(false)}>
                { registered ? 'Неправильный логин или пароль' : 'Произошла ошибка, попробуйте позже' }
            </Snackbar>

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={showEmail} color="success" variant="solid">
                Аккаунт создан. Напишите на почту easymoney.tech.noreply@gmail.com, 
                чтобы администратор активировал аккаунт
            </Snackbar>
            <Link onClick={() => setRegistered(!registered)}>{ registered ? 'Зарегистрироваться' : 'Войти в аккаунт' }</Link>
        </Stack>
    )
};

export default LoginPage;

/**
 * Checks if email is valid by regex. 
 * @param { string } email 
 * @returns { boolean } Valid email status.
 */
function isValidEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
};